import React from 'react'
import Nav from './navbar-white'
import Footer from './footer'
import ContactForm from './contactForm.js'
import { useState, useEffect } from "react"


export default function Contact() {

    useEffect(() => {
        document.querySelector('a.app[href="/contact"] .app-wrap').classList.add('active')
    });

    return (
        <>
            <Nav />
            <main id="main-contact">
                <div className="contact-form-container">
                    <ContactForm />
                </div>
            </main>
            <Footer/>
        </> 
    )
}