import React, { useState, useEffect } from 'react'

export default function ContactForm () {

  var nameRegex = /\w+/gi
  var emailRegex = /.+@.+\..+/gi


  function checkName() {
    if (!document.querySelector('input#name').value.match(nameRegex)) {
      document.querySelector('input#name').closest('.form-field').nextElementSibling.classList.remove('hidden');
    } else {
      document.querySelector('input#name').closest('.form-field').nextElementSibling.classList.add('hidden');
    }
    toggleSubmitDisabled();
  }

  function checkEmail() {
    console.log('check email')
    toggleSubmitDisabled();
    document.querySelector('input#email').removeEventListener('blur', checkEmail)
    if (!document.querySelector('input#email').value.match(emailRegex)) {
      document.querySelector('input#email').closest('.form-field').nextElementSibling.classList.remove('hidden');
    } else if (document.querySelector('input#email').value.match(emailRegex)) {
      document.querySelector('input#email').closest('.form-field').nextElementSibling.classList.add('hidden');
    }
    if (!document.querySelector('input#email').classList.contains('listening')) {
      document.querySelector('input#email').classList.add('listening');
      document.querySelector('input#email').addEventListener('input', function(e) {
        console.log('checking email input')
        if (!document.querySelector('input#email').value.match(emailRegex)) {
          document.querySelector('input#email').closest('.form-field').nextElementSibling.classList.remove('hidden');
        } else if (document.querySelector('input#email').value.match(emailRegex)) {
          document.querySelector('input#email').closest('.form-field').nextElementSibling.classList.add('hidden');
        }
        toggleSubmitDisabled();
      });
    }
  }

  function checkMsg() {
    if (document.querySelector('textarea#message').value === '') {
      document.querySelector('textarea#message').closest('.form-field').nextElementSibling.classList.remove('hidden');
    } else {
      document.querySelector('textarea#message').closest('.form-field').nextElementSibling.classList.add('hidden');
    }
    toggleSubmitDisabled();
  }

  function toggleSubmitDisabled() {
    if (document.querySelector('input#name').value.match(nameRegex) && document.querySelector('input#email').value.match(emailRegex) && document.querySelector('textarea#message').value !== '') {
      //console.log('ENABLE THE BUTTON');
      document.querySelector('input.form-submit').disabled = false; 
    } else if (!document.querySelector('input.form-submit').disabled) {
      document.querySelector('input.form-submit').disabled = true;  
    }
  }
  
  useEffect(() => {
  });

  const [isSubmitted, setSubmitted] = useState(false);
  const [name, setName] = useState("");
  const [email, setEmail] = useState("");
  const [message, setMessage] = useState("");

  const handleSubmit = (e) => {
      e.preventDefault();

      // create a new XMLHttpRequest
      var xhr = new XMLHttpRequest();

      // get a callback when the server responds
      xhr.addEventListener('load', () => {
          // update the response state and the step
          //console.log(xhr.responseText);
          document.querySelector('#contact-form')
          setSubmitted(true)
      });

      // open the request with the verb and the url
      xhr.open('GET', 'http://belucky.band/contactForm/contactform2.php?sendto=' + email + 
                              '&name=' + name + 
                              '&message=' + message);

      // send the request
      xhr.send();
      
  };

  return isSubmitted ? (
    <div id="form-submitted-msg">
      <h3 className="text-center">Message sent</h3>
      <div className="text-center spacing_top--med spacing_bottom--lrg">Thanks for your message, we'll get back to you as soon as possible.<br/><br/>You can also reach out to us on socials.</div>
      <div className="homepage-socials">
        <div className="socialicons">
            <a className="socialiconlinks" href="https://open.spotify.com/artist/38kc2HMIOcksnSIylCoEIb?si=5ltvSfzHRdeVare8gEThiA/" target="_blank">
                <img src="images/social_logos_cropped/simple_icons/spotify.svg" alt="spotify logo"/>
            </a>
            <a className="socialiconlinks" href="https://www.instagram.com/beluckyband/" target="_blank">
                <img src="images/social_logos_cropped/simple_icons/ig2.svg" alt="instagram logo"/>
            </a>
            <a className="socialiconlinks" href="https://www.tiktok.com/@beluckyband" target="_blank">
                <img src="images/social_logos_cropped/simple_icons/tt.svg" alt="tiktok logo"/>
            </a>
             <a className="socialiconlinks" href="https://www.youtube.com/channel/UCc3VCcdkHzRJtGqlepUFkCw" target="_blank">
                <img src="images/social_logos_cropped/simple_icons/yt.svg" alt="youtube logo"/>
            </a>
            <a className="socialiconlinks" href="https://www.facebook.com/people/Be-Lucky/61560770817469/" target="_blank">
                <img src="images/social_logos_cropped/simple_icons/fb.svg" alt="facebook logo"/>
            </a>
        </div>
      </div>
    </div>
  ) : (
    <>
    
    <form id="contact-form" action="contactform2.php" method="POST" onSubmit={(event) => handleSubmit(event)}>
    <h1 className="spacing_bottom--sml">Get in touch</h1>
    <div className='contact-form form-field name'>
        <label htmlFor="name"></label>
        <input type="text" id="name" name="fullName" placeholder="Name*" autoComplete="on" value={name} onChange={(e) => setName(e.target.value)} onInput={checkName}/>
      </div>
      <div className='contact-form form-field error-field hidden'>
        <span>Please enter your name so we know who you are!</span>
      </div>
      <div className='contact-form form-field name'>
        <label htmlFor="email" required></label>
        <input type="email" id="email" placeholder="Email*" autoComplete="on" value={email} onChange={(e) => setEmail(e.target.value)} onBlur={checkEmail}/>
      </div>
      <div className='contact-form form-field error-field hidden'>
        <span>Please enter a valid email address.</span>
      </div>
      <div className='contact-form form-field name'>
        <label htmlFor="message"></label>
        <textarea name="message" id="message" cols="30" rows="10" placeholder="Type your message here" value={message} onChange={(e) => setMessage(e.target.value)} onInput={checkMsg}></textarea>
      </div>
      <div className='contact-form form-field error-field hidden'>
        <span>Please enter a message!</span>
      </div>
      <input className="form-submit" type="submit" value="Send" disabled/>
    </form>

    </>

  )

}