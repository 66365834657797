import React from 'react'
import { useState, useEffect } from "react"
import { Outlet, Link, NavLink } from "react-router-dom";

export default function Footer() {

    useEffect(() => {

        document.querySelectorAll('.site-map h4').forEach(btn => {
            btn.addEventListener('click', function(e) {
                if (e.target.nextElementSibling.style.maxHeight === '0px' || e.target.nextElementSibling.style.maxHeight === '') {
                    e.target.classList.add('open');
                    e.target.nextElementSibling.style.maxHeight = e.target.nextElementSibling.scrollHeight + 'px';
                } else {
                    e.target.classList.remove('open');
                    e.target.nextElementSibling.style.maxHeight = '0px';
                }
            })
        })

        /*var footerPlacement =  function() {

            if (window.matchMedia("(min-width: 768px)").matches) {
                if (document.querySelector('main').getBoundingClientRect().bottom + document.querySelector('footer').scrollHeight >= window.innerHeight) {
                    console.log('remove fixed');
                    document.querySelector('footer').classList.remove('fixed')
                } else if (window.innerHeight - document.querySelector('footer').getBoundingClientRect().bottom > 0) {
                    console.log('add fixed');
                    document.querySelector('footer').classList.add('fixed')
                }
            }
        };

        footerPlacement();
        window.addEventListener('resize', footerPlacement);*/

    })

    return (
        <>
        <footer id="footer">
            <div className="footer-content">
                <div className="footer-block">
                <div className="footer-info-wrap">
                    <div className="site-map-wrap">
                        <div className="site-map">
                            <h4>General</h4>
                            <ul>
                                {/*<li><Link reloadDocument to='/about'>About</Link></li>
                                <li><Link reloadDocument to='/tunage'>New Releases</Link></li>
                                <li><Link reloadDocument to='/shows'>Upcoming Shows</Link></li>
                                <li><Link reloadDocument to='/news'>News</Link></li>*/}
                                <li><Link reloadDocument to='/contact'>Contact Us</Link></li>
                            </ul>
                        </div>
                        <div className="site-map">
                            <h4>Socials</h4>
                            <ul>
                                <li><a href="https://www.instagram.com/beluckyband/">Instagram</a></li>
                                <li><a href="https://www.tiktok.com/@beluckyband">TikTok</a></li>
                                <li><a href="https://www.youtube.com/channel/UCc3VCcdkHzRJtGqlepUFkCw">YouTube</a></li>
                                <li><a href="https://www.facebook.com/people/Be-Lucky/61560770817469/">Facebook</a></li>
                            </ul>
                        </div>
                    </div>
                </div>
                </div>

                <div className="footer-block">
                <div className="footer-socials">
                    <h3 className="spacing_bottom--sml">Follow us</h3>
                    <div className="footer-social-links spacing_bottom--sml">
                        <div className="socialicons">
                            {/*<Link className="socialiconlinks" to="https://open.spotify.com/artist/38kc2HMIOcksnSIylCoEIb?si=5ltvSfzHRdeVare8gEThiA/" target="_blank">
                                <img src="/images/social_logos_cropped/simple_icons/spotify.svg" alt="spotify logo"/>
                            </Link>
                            <Link className="socialiconlinks" to="" target="_blank">
                                <img src="images/social_logos_cropped/simple_icons/apple.svg" alt="apple music logo"/>
                            </Link>*/}
                            <Link className="socialiconlinks" to="https://www.instagram.com/beluckyband/" target="_blank">
                                <img src="/images/social_logos_cropped/simple_icons/ig2.svg" alt="instagram logo"/>
                            </Link>
                            <Link className="socialiconlinks" to="https://www.tiktok.com/@beluckyband" target="_blank">
                                <img src="/images/social_logos_cropped/simple_icons/tt.svg" alt="tiktok logo"/>
                            </Link>
                            <Link className="socialiconlinks" to="https://www.youtube.com/channel/UCc3VCcdkHzRJtGqlepUFkCw" target="_blank">
                                <img src="/images/social_logos_cropped/simple_icons/yt.svg" alt="youtube logo"/>
                            </Link>
                            <Link className="socialiconlinks" to="https://www.facebook.com/people/Be-Lucky/61560770817469/" target="_blank">
                                <img src="/images/social_logos_cropped/simple_icons/fb.svg" alt="facebook logo"/>
                            </Link>
                        </div>
                    </div>
                    <a className="mailto" href="mailto:contact@belucky.band">
                        <div className="contact-details eml">beluckybandofficial@gmail.com</div>
                    </a>
                </div>
                </div>

                <div className="footer-block">
                <div className="footer-info-wrap">
                    <div className="copyright-notice">
                        <p>Copyright &copy; Be Lucky</p>
                    </div>
                </div>
                </div>

            </div>
        </footer>
        </> 
    )
}