import React from 'react'
import Overlay from './overlay'
import Apps from './apps'
import { Outlet, Link, NavLink } from "react-router-dom";

export default function Nav() {
    
    function openNav() {
        if (!document.querySelector('.nav-content-wrap').classList.contains('collapse')) {
            document.querySelector('.nav-content-wrap').classList.add('collapse');
            document.querySelector('.overlay').classList.remove('active');
        } else {
            document.querySelector('.nav-content-wrap').classList.remove('collapse');
            document.querySelector('.overlay').classList.add('active');
        }
    }

    function openFlyout() {
        if (!document.querySelector('.navbar-lrg-flyout').classList.contains('open')) {
            document.querySelector('.navbar-lrg-flyout').classList.add('open');
            document.querySelector('.overlay').classList.add('active');
        } else {
            document.querySelector('.navbar-lrg-flyout').classList.remove('open');
            document.querySelector('.overlay').classList.remove('active');
        }
    }
    
    window.addEventListener('resize', function(){
        if (document.querySelector('.nav-content-wrap').style.maxHeight) {
            document.querySelector('.nav-content-wrap').style.maxHeight = document.querySelector('.nav-content-wrap').scrollHeight + "px";
        }
    })

    var oldScrollY = window.scrollY;
    window.onscroll = function(e) {
        if (window.matchMedia("(max-width: 767px)").matches) {
            if (window.scrollY > '50') {
                if(oldScrollY < window.scrollY){
                    document.querySelector('#navbar').classList.add('collapse');
                    document.querySelector('.nav-content-wrap').classList.add('collapse');
                } else {
                    document.querySelector('#navbar').classList.remove('collapse');
                }
                oldScrollY = window.scrollY;
            } else if (document.querySelector('#navbar').classList.contains('collapse')) {
                document.querySelector('#navbar').classList.remove('collapse');
            }
        } else {
            if (document.querySelector('.navbar-lrg-flyout').classList.contains('open')) {
                document.querySelector('.navbar-lrg-flyout').classList.remove('open');
                document.querySelector('.overlay').classList.remove('active');
            }
        }
    }
   
    return (
        <>
        <header>
            <div id='navbar' className="home">
                <div id="navbar-lrg">
                    <div id="menu--lrg">
                        <div className="menu--lrg-wrap" onClick={openFlyout}>
                            <img id='hamburger-icon2' className="" src="/images/vinyl-1.png" alt='menu'/>
                        </div>
                    </div>
                    <div className="navbar-lrg-flyout">
                        <div id="flyoutlinks">
                            <NavLink reloadDocument className={({ isActive }) => (isActive ? 'flyoutlinks selected' : 'flyoutlinks')} to="/">HOME</NavLink>
                            {/*<NavLink reloadDocument className={({ isActive }) => (isActive ? 'flyoutlinks selected' : 'flyoutlinks')} to="/tunage">TUNAGE</NavLink>*/}
                            {/*<NavLink reloadDocument className={({ isActive }) => (isActive ? 'flyoutlinks selected' : 'flyoutlinks')} to="/shows">SHOWS</NavLink>*/}
                            {/*<NavLink reloadDocument className={({ isActive }) => (isActive ? 'flyoutlinks selected' : 'flyoutlinks')} to="/news">NEWS</NavLink>*/}
                            {/*<NavLink reloadDocument className={({ isActive }) => (isActive ? 'flyoutlinks selected' : 'flyoutlinks')} to="/about">ABOUT</NavLink>*/}
                            <NavLink reloadDocument className={({ isActive }) => (isActive ? 'flyoutlinks selected' : 'flyoutlinks')} to="/contact">CONTACT</NavLink>
                            {/*<NavLink reloadDocument className={({ isActive }) => (isActive ? 'flyoutlinks selected' : 'flyoutlinks')} to="/merch">SHOP</NavLink>*/}
                            {/*<NavLink reloadDocument className={({ isActive }) => (isActive ? 'flyoutlinks selected' : 'flyoutlinks')} to="/cpod">CPOD</NavLink*/}
                        </div>
                    </div>
                    <div className="navbar-links-wrap--lrg hidden">
                        <div className="logo--lrg-wrap">
                            <NavLink reloadDocument to="/" className='link'>
                                <img id="navbarlogo" className="" src="/images/mxd logos-02.png" alt='logo'/>
                            </NavLink>
                        </div>
                        <NavLink reloadDocument className="navbar-links--lrg" to='/tunage'>MUSIC</NavLink>
                        <NavLink reloadDocument className="navbar-links--lrg" to='/shows'>SHOWS</NavLink>
                        <NavLink reloadDocument className="navbar-links--lrg" to='/news'>NEWS</NavLink>
                        <NavLink reloadDocument className="navbar-links--lrg" to='/contact'>CONTACT</NavLink>
                        {/*<a className="navbar-links--lrg" href='merch'>SHOP</a>*/}
                    </div>
                    <div id="account--lrg">
                        <div className="account--lrg-wrap">
                            {/*<div id="accountnavigation">
                                <div id="cart-link"></div>
                                <div id="profile-link"></div>
                            </div>*/}
                        </div>
                    </div>                 
                </div>
                <div id='navbartop'>  
                    <div id='hamburger'>
                        <img id='hamburger-icon' className="" onClick={openNav} src="/images/vinyl-1.png" alt='menu'/>
                    </div>                     
                    <div id='navbartitle'>
                        <a href="/" className='link'>
                            <img id="navbarlogo" className="hidden" src="/images/mxd logos-02.png" alt='logo'/>
                        </a>
                    </div>
                    <div id='account'>
                        
                    </div>
                </div>
                <div className="nav-content-wrap collapse hp">     
                    <Apps />
                </div>
            </div>
        </header>
        <Overlay />
        </>
    )
}