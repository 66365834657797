import React from 'react'

function Overlay() {

    function closeNav() {
        document.getElementById('hamburger-icon').click();
    }

    return (
        <>  
            <div className="overlay" onClick={closeNav}></div>
        </> 
    )
}

export default Overlay;